html.dark {
  background-color: rgb(15, 23, 42);
}

.color-rosal {
  color: #bc1c50;
}
.bg-rosal-500 {
  background-color: #bc1c50;
}
.border-rosal-500 {
  border-color: #bc1c50;
}
