@tailwind base;
@tailwind components;
@tailwind utilities;

img {
  width: 50%;
}
.cell-animation {
  animation: revealCharCell linear;
  animation-duration: 0.15s;
}

.h-screen {
  height: 100vh;
  max-height: -webkit-fill-available;
}

@media screen and (max-height: 700px) {
  img {
    width: 25%;
  }
  .mb-8 {
    margin-bottom: 10px;
  }
  .w-14 {
    width: 2.5rem;
  }

  .h-14 {
    height: 2.5rem;
  }

  button {
    height: 45px !important;
  }
}

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
